.product-carousel {
    max-width: 500px;
    width: 50%;
    display: flex;
    align-items: center;
}

@media only screen and (min-width:769px) and (max-width:813px) {
    .form-btn {
        padding: 10px 15px;
    }
}

@media (max-width: 898px) {
    .sales-btn {
        font-size: 11px;
    }
}

@media (max-width: 838px) {
    .modal-container {
        left: 10%;
        width: 80% !important;
        margin-left: 0px;
        top: 0%;
    }
    
}

@media (max-width: 768px) {
    .supplier-wrapper {
        align-items: center;
    }
    .modal-container {
        left: 10%;
        width: 80% !important;
        margin-left: 0px;
        /* transform: translate(-50%, -50%); */
        top: 0%;
    }
    .modal-div {
        width: 100%;
    }
    .product-carousel {
        margin-top: 20px;
    }
    .contact-links {
        margin-bottom: 5px;
    }
    .carousel {
        max-width: 500px;
        width: 100%;
    }
    .supllier-contact {
        flex-direction: column;
    }
    .supplier-title {
        width: 100%;
        text-align: center;
    }
    .supplier-info {
        margin: auto;
        padding:0 15px;
    }
    .features-container {
        flex-direction: column;
        align-items: center;
    }
    .features-title {
        color: white;
        margin-top: 20px;
    }
    .features {
        background: var(--main-red);
    }
    .supplier,
    .field,
    .contact-form {
        width: 100%;
    }
    .contact-form {
        width: 70%;
        margin: auto;
    }
    .supplier {
        padding: 20px;
    }
    .feature-div {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .procontrol-video {
        border: 3px solid var(--main-red);
        margin-top: 180px;
        width: 90%;
        margin-left: 5%;
        height: 510px;
    }
    .hero {
        background-position: 0 0;
        background-position: initial;
        background-size: initial;
        background-size: cover;
    }
    .product-info {
        flex-direction: column;
    }
    .product-info-container,
    .product-carousel {
        width: 100%;
    }
    .product-carousel {
        margin: auto;
        margin-top: 20px;
    }
    .hero-container {
        width: 100%;
    }
    .feature-div img {
        width: 200px;
    }
}

@media (max-width: 992px) {
    .feature-div img {
        width: 250px;
    }
    .hero {
        background-position: 0 0;
        background-position: initial;
        background-size: cover;
    }
    .contact-form {
        padding: 40px 40px;
    }
    .header-container {
        width: 90%;
        /* margin-left: 5%; */
        margin-right: 5%;
    }
    .procontrol-video {
        border: 3px solid var(--main-red);
        margin: 0px 20px;
        width: 90%;
        height: 450px;
        margin-left: 5%;
        margin-right: 5%;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

@media (max-width: 576px) {
    .modal-div {
        width: 100%;
    }
    .modal-form {
        width: 100%;
    }
    .header-container {
        flex-direction: column;
        width: 100%;
    }
    .header-container>* {
        margin: auto;
    }
    .supplier-info>br {
        display: none;
    }
    .supplier {
        padding: 20px 0px;
    }
    .hero {
        height: auto;
        height: initial;
    }
    .procontrol-video {
        height: 300px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .submit-btn {
        width: 100%;
        margin-bottom: 20px;
    }
    .supplier-title {
        margin-bottom: 0px;
    }
    .form-btn {
        padding: 10px 1px;
    }
    .contact-form {
        padding: 20px;
    }
    .form-title,
    .supplier-title,
    .form-title {
        text-align: center !important;
        width: 100%;
    }
}

@media (max-width: 480px) {
    .not-launched-text {
        text-align: center;
        padding: 0 10px;
    }
}
@media (max-width: 430px) {
    .contact-form {
        width: 90%;
    }
}

@media (max-width: 380px) {
    .features-title{
        font-size:35px;
    }    
}

@media (max-width: 320px) {
    .features-title,
    .supplier-title {
        font-size: 30px;
    }
    .g-recaptcha {
        width: 100%;
        margin-left: -20px;
    }
    .modal-container {
        left: 2%;
        width: 96% !important;
        margin-left: 0px;
        margin-top: 75px;
        /* transform: translate(-50%, -50%); */
        top: 0%;
    }
    .contact-form {
        width: 100%;
    }
}

/*Screen Height */
@media (max-height: 670px) {
    .modal-container {   
        margin-top: 100px;
    }
}