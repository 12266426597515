/* @font-face {
    font-family: "Gotham Light";
    src:url(../../assets/fonts/ufonts.com_gotham-light-opentype.otf)
}
@font-face {
    font-family: "Gotham Normal";
    src:url(../../assets/fonts/ufonts.com_gotham-book-opentype.otf)
} */




input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

.is-inlineblock {
    display: inline-block !important;
}


/*Fix for React Bootstrap Modal padding*/

body:not(.modal-open) {
    padding-right: 0px !important;
}

.modal-open {
    overflow: hidden;
    padding-right: 0 !important;
}

html,
body {
    overflow-x: hidden
}

body {
    margin: 0;
}

.text-medium {
    font-size: 14px;
}

.text-large {
    font-size: 35px;
}

.text-small {
    font-size: 12px !important;
}

.center {
    text-align: center;
}

.text-bold {
    font-weight: 600
}

:root {
    --main-red: #e30613;
    --main-grey: #ececec;
}


/*General*/

.app {
    font-family: 'Arial', 'sans-serif';
}

.gce-logo {
    width: 150px;
    height:100px;   
}

.uppercase {
    text-transform: uppercase;
}

.white {
    color: white;
}

.red {
    color: var(--main-red);
}

.btn-red {
    background: var(--main-red);
    border: none;
    color: rgb(247, 224, 224);
    text-transform: uppercase;
    padding: 7px;
    cursor: pointer;
}

.btn-grey {
    background: var(--main-grey);
    border: none;
    color: var(--main-red);
    text-transform: uppercase;
    padding: 7px;
    cursor: pointer;
}

.form-btn {
    padding: 10px 10px;
}

.form-title {
    margin: 0px;
}


/*Modal Container*/

.lightbox {
    height: 100vh;
    width: 100vw;
    background: rgba(32, 27, 27, 0.59);
    position: fixed;
    top: 0;
    z-index: 10000;
}

.modal-container {
    position: fixed;
    width: 800px !important;
    width: 100%;
    z-index: 100;
    left: 50%;
    margin-left: -400px;
    margin-top: 200px;
    color: var(--main-red);
    background-image: url('../../assets/world_map.svg');
    background-color: var(--main-grey);
    background-repeat: no-repeat;
    background-position: 95%, 100%;
    max-width: 800px;
    height: 350px;
    display: flex;
    justify-content: flex-start;
}

.margin-button {
    margin-top: 10px;
}

.modal-content {
    border: none !important;
}

.modal-text {
    font-size: 16px;
    margin-bottom: 10px;
    max-width: 95%;
}

.modal-form {
    min-width: 180px;
    display: flex;
    flex-direction: column;
}

/*
    Header 
    
*/
.header-container {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}

.modal-form .btn-red {
    padding: 10px 12px;
}

.modal-select {
    border: 2px solid var(--main-red);
    font-size: 14px;
    border-radius: 0px;
    -webkit-appearance: none;
    margin: 10px 0px;
    background: white;
}
select {
    padding-left: 20px;
    color: var(--main-red);
    text-transform: uppercase;
    -moz-appearance: none;
    padding: 5px;
}

.modal-div {
    width: 350px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 0;
}


/*Procontrol*/

.procontrol-video {
    border: 4px solid var(--main-red);
    margin: 10px 0px 30px;
    width: 900px;
    /* height: 350px; */
    height: 510px;
    max-width: 100%;
    max-height: calc((100vw - 40px) / (16/9));
}
.vjs-control-bar{
    background:transparent!important;
}
.vjs-paused .vjs-big-play-button,
.vjs-paused.vjs-has-started .vjs-big-play-button {
   display: block;
}
.vjs-big-play-button{
    border:none !important;
}
.hero {
    background-image: url('../../assets/GCE_Landing_header-min.jpg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: 10%;
    /* height: 775px; */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.hero-container {
    width: 100%;
    max-width: 900px;
    position: relative;
}

.globe {
    height: auto;
    width: 20px;
    margin-right: 15px;
    vertical-align: middle;
}

.change-region-container {
    display: flex;
    justify-content: center;
    margin-top: -15px;
}

.region-btn {
    color: var(--main-red) !important;
    text-decoration: underline !important;
    font-size: 14px;
    cursor: pointer;
    margin: 0 auto;
    display: block;
}

.language-dropdown {
    padding: 7px 7px 8px;
}


/*Features*/

.features {
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: -1px;
}

.features-title {
    font-size: 50px;
    /* margin: 30px 0; */
    margin-bottom: 20px;
}

.bottom-container {
    background: var(--main-red);
    height: 255px;
    width: 100%;
    margin-top: -230px;
    position: relative;
    z-index: -100;
}

.features-container {
    display: flex;
}

.features-container img {
    height: auto;
    width: 200px;
}

.feature-div p {
    max-width: 300px;
    padding: 0 20px;
}

.feature-div img {
    width: 300px;
}

.feature-div {
    margin: 0 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.feature-div h5 {
    font-size: 30px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 20px;
}


/*Supplier */

.supllier-contact {
    display: flex;
}
.not-launched-text{
    
}

.supplier {
    width: 50%;
    background: var(--main-red);
    padding: 20px 80px;
   

}

.supplier-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.supplier-title {
    margin-top: 0;
    text-align: right;
}

.supplier-icon,
.pin-icon,
.phone-icon,
.email-icon {
    height: 30px;
    margin-right: 10px;
}
.email-container {
    margin-bottom:7px;
    height: 21px;
    width: 100%;
    
}
.phone-icon {
    height: 25px;
    margin-left: -4px;
}

.pin-icon {
    margin-left: -7px;
    height: 38px;
    width: 30px;
}

.supplier-icon {
    margin-left: -6px;
    margin-top: -6px;
}

.supplier-name {
    font-weight: bold;
    font-size: 16px;
    margin-left: -7px;
}

.email-icon {
    height: 17px;
    vertical-align: middle;
}

.supplier-phone {
    margin-left: -3px;
}

.info-title {
    /* margin-left: 16px; */
    margin: 20px 0;
}

.info-title:first-child {
    /* margin-left: 16px; */
    margin-top: 0px;
}

.supplier-info>img,
.pin-icon {
    vertical-align: middle;
}

.address {
    display: flex;
}
.address p {
    margin-bottom:5px;;
}

.address-list {
    margin-left: -6px;
}

.line_one,
.line_two,
.line_three,
.postcode {
    margin-bottom: 0px;
    line-height: 2em;
}

.contact-links:hover {
    color: white;
}

.email-container {
    display: flex;
}

.contact-form {
    width: 50%;
    padding: 20px 70px;
}

.success-msg {
    margin-top: 10px;
}

.error-msg {
    margin-top: 10px;

}
.privacy-container {
    display: flex;
}

.privacy-container label {
    max-width: 370px;
}

.privacy-checkbox {
    margin-top: 11px;
    margin-right: 4px;
    border: 0px !important;
}

.contact-form input,
.contact-form textarea {
    display: block;
    border: 0.5px solid var(--main-red);
}

.contact-form label {
    color: rgb(107, 106, 106);
}

.submit-btn {
    padding: 4px 55px;
    background: white;
    margin-top: 5px;
    max-width: 384px;
}

.submit-btn:hover {
    background: var(--main-red);
    color: white;
}

.sales-btn {
    width: 45%;
    max-width: 190px;
}

.formNavContainer {
    display: flex;
    width: 100%;
    max-width: 380px;
    justify-content: space-around;
}


/*Product information*/
.more-info-btn {
    text-decoration: none;
    padding:7px 14px;
}
.more-info-btn:hover {
    text-decoration: none;
    color:var(--main-red);
}

.more-info-container {
    display:flex;
    justify-content:flex-end;
}
.product-info {
    display: flex;
    margin: 20px auto;
    justify-content: center;
}

.product-info-container {
    width: 500px;
}

.product-info-tab {
    background: var(--main-red);
    padding: 20px;
}

.nav-tabs,
.nav-link {
    border-radius: 0px !important;
    font-weight: bold;
    font-size: 15px;
}

.nav-tabs a[data-rb-event-key="product"] {
    background: var(--main-grey) !important;
    color: var(--main-red) !important;
    border: none !important;
}

.nav-item.nav-link.active{
    background: var(--main-red) !important;
    color: white !important;
    border: none !important;
}
.nav-tabs a[data-rb-event-key="technical"] {
    background: var(--main-grey) !important;
    color: var(--main-red) !important;
    border: none !important;
}

.nav-tabs .nav-link {
    width: 50%;
}

.product-carousel {
    width: 500px;
    display: flex;
    align-items: center;
}

.carousel {
    width: 500px;
}

.slider-btn {
    height: 35px;
}

.w-100 {
    max-width: 400px;
    margin: 0 auto;
}

.features-list,
.advantages-list {
    margin: 0px;
    /* margin-left: -11px; */
    font-size: 12px;
    width: 100%;
}


/*Table*/

.technical-table {
    font-size: 11px;
}

table tbody tr td:first-child {
    padding: 10px;
    background-color: var(--main-red);
    width: 200px;
    color: white;
    -webkit-box-shadow: inset -40px 0 40px -30px #a51e1e;
    box-shadow: inset -40px 0 40px -30px #a51e1e;
    border-bottom: 1px solid #555555;
    border-right: 1px solid #555;
}

table tbody tr td {
    color: #456;
    padding: 6px;
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
    border-top: 1px solid #000;
    vertical-align: middle;
}

table tbody tr:nth-child(odd) {
    background: var(--main-grey)
}


/*Form*/

.field {
    max-width: 385px;
    width: 100%;
    padding: 5px;
}

.contact-form label {
    margin-bottom: 0;
    margin-top: 8px;
}

.contact-form input:focus,
.contact-form textarea:focus {
    border: 1px solid var(--main-red);
}

.message-field {
    height: 100px;
}


/*animations*/

.hide-lightbox {
    display: none;
}

.show-lightbox {
    display: block;
}

.closeModal {
    animation: closeModalContainer 1s ease-in-out;
}

.openModal {
    animation: openModalContainer 1s ease-in-out;
}

.g-recaptcha {
    margin-left: -65px;
    transform: scale(0.77);
    width: 304px;
    margin-left: -37px
}

/*Footer Section*/
.gce-footer-logo {
    height:40px;
}
.footer-section {
    background: var(--main-red);
    height: 130px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.footer-section>a {
    display: inline-block;
}

.footer-line {
    width: 300px;
    height: 1px;
    background: white;
}
.privacy-policy-link:hover {
    color: white
}

/*Toast */
.notification {
    text-align: center;
}
#regionTitle {
    margin-bottom:0px;
}
.toast-language-select {
    margin: 3px 0px 5px 5px;
    background:none;
    color:white;
}
.toast-language-select option {
    color:red; 
}
.toast-select-container {
    height: 35px;
    margin-top:5px;
    
}


@keyframes closeModalContainer {
    from {
        transform: translateY(0%)
    }
    to {
        transform: translateY(-200%)
    }
}

@keyframes opemModalContainer {
    from {
        transform: translateY(-200%)
    }
    to {
        transform: translateY(0%)
    }
}


/*Media Queries*/

@media only screen and (min-width: 1700px) {
    .hero {
        background-position: 10% 50%;
    }
}